import { useState } from "react";
import LayoutOne from "../Layout/Layout_One";
import Imgsection from "../components/Comp_Layouts/HeroTwo";
import { Formik } from "formik";

export default function Contact() {
  const [name, setName] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  // const { notifySuccess } = useCustomToast();
  const initialValues: {
    fullname: string;
    email: string;
    subject: string;
    message: string;
  } = { email: "", subject: "", message: "", fullname: "" };

  return (
    <LayoutOne>
      <Imgsection
        image="/images/banner27.jpg"
        sub_title="Asked Questions?"
        title="Faqs"
      />
      <div className=" bg-main py-[55px] text-[14px] md:text-[18px] ">
        <p className=" w-[78%] m-auto text-white ">
          When you need information, you want it quickly. We understand and
          we're here for you. Use this simple form to contact us and we'll reply
          quickly to assist. We endeavour to answer all enquiries within 24
          hours on business days. We will be happy to answer your questions.
        </p>
      </div>
      <div className=" w-[90%] m-auto flex flex-col md:flex-row pt-[20px] justify-between text-[14px] ">
        <div className=" md:w-[50%] ">
          <h1 className="font-bold py-[20px] text-[20px] md:text-[25px] text-sub_main ">
            Contact Information
          </h1>
          <p>
            We now offer after-hours support about your deposit and withdrawal
            and need to speak to a representative after normal business hours,
            please contact our After Hours Contact Center at: ‪+44 7366 289958
          </p>
          <div className=" mt-[20px] bg-yellow-00 min-h-[100px] flex md:items-center  flex-col md:flex-row justify-between text-[14px] md:text-[18px] ">
            <div className=" md:w-[42%] min-h-[80%] ">
              <p className=" text-main ">
                <b>We Are Here</b>
              </p>
              <p className="text-[14px] md:text-[16px]">
                12 John Princes Street, London, W1G 0JR
              </p>
            </div>
            <div className=" md:w-[47%] min-h-[80%] ">
              <p className="text-main">
                <b>Contact Number</b>{" "}
              </p>
              <p className=" flex text-[14px] md:text-[16px] ">
                <b>
                  <span className="text-main">Phone</span>
                </b>
                : ‪+44 7366 289958
              </p>
            </div>
          </div>
          <div className=" pb-[20px] md:pb-20 ">
            <p className=" py-[10px] text-[13px] text-main ">
              Complete and submit the form below
            </p>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={async (values) => {
                // console.log(values);
                setEmail("");
                setName("");
                setMessage("");
                setSubject("");
                // notifySuccess(
                //   "Thanks for reaching out to us, We would get back to you."
                // );
              }}
            >
              {(prop) => (
                <form onSubmit={prop.handleSubmit}>
                  <div className=" h-[350px] md:h-[300px] flex flex-wrap justify-between ">
                    <div className=" py-[10px] px-[10px] w-full md:w-[47%] h-[70px] bg-[#e5e5e5] ">
                      <input
                        className=" font-semibold h-full w-full bg-[#e5e5e5] placeholder:text-[#4e4e4e] placeholder:text-[14px] outline-none "
                        placeholder="Full Name *"
                        name="fullname"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className=" py-[10px] w-full px-[10px] md:w-[47%] h-[70px] bg-[#e5e5e5] ">
                      <input
                        className=" font-semibold placeholder:text-[#4e4e4e] h-full w-full bg-[#e5e5e5] placeholder:text-[14px] outline-none "
                        placeholder="Email *"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="py-[10px] w-full px-[10px] h-[70px] bg-[#e5e5e5]">
                      <input
                        className=" font-semibold placeholder:text-[#4e4e4e] h-full w-full bg-[#e5e5e5] placeholder:text-[14px] outline-none "
                        placeholder="Subject *"
                        name="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="py-[10px] w-full px-[10px] h-[70px] bg-[#e5e5e5]">
                      <textarea
                        className="font-semibold placeholder:text-[#4e4e4e] h-full w-full bg-[#e5e5e5] placeholder:text-[14px] outline-none "
                        placeholder="Message *"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <button
                      className=" bg-sub_main px-10 py-3 rounded-xl text-main hover:text-teal-700 mt-[10px]  "
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div className=" text-black md:w-[48%] ">
          <div className=" h-[600px] ">
            <iframe
              title="my map"
              className="mapdf"
              width="100%"
              height="100%"
              src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=12%20John%20Princes%20Street%2C%20London%20EC2N%202DL+(my%20map)&amp;t=k&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              // <iframe width="600" height="450" style="border:0" loading="lazy" allowfullscreen
              // src="https://www.google.com/maps/embed/v1/place?q=12%20John%20Princes%20Street%2C%20London%2C%20W1G%200JR&key=..."></iframe>
            ></iframe>
          </div>
        </div>
      </div>
    </LayoutOne>
  );
}
